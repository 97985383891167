<template>
    <div>
        <portal to="layout-default-header">
            <header-index :title="$t('posts.edit.title')">
                <template #create-new>
                    <b-btn
                        variant="light"
                        class="btn-min-width mr-3"
                        :disabled="!post.changed()"
                        @click="post.reset()"
                    >
                        {{ $t('common.actions.undoChanges') }}
                    </b-btn>

                    <btn-resource
                        class="btn-min-width"
                        variant="primary"
                        :resource="post"
                        @click="savePost()"
                    >
                        {{ $t('common.actions.saveChanges') }}
                    </btn-resource>
                </template>
            </header-index>
        </portal>

        <b-row align-h="center">
            <b-col cols="6">
                <wait-for-resource :resource="post">
                    <post-form
                        ref="videoForm"
                        :post="post"
                        @submit.prevent="savePost()"
                    />

                    <!-- Delete Post Box -->
                    <!-- TODO: Make a reusable component for this. -->
                    <div class="d-flex mt-4 py-4 px-5 bg-striped">
                        <div class="d-flex flex-column flex-grow-1">
                            <strong>
                                {{ $t('posts.edit.deleteMedia') }}
                            </strong>

                            <small>
                                {{ $t('common.messages.deleteWarning') }}
                            </small>
                        </div>

                        <div class="d-flex flex-column col-3 justify-content-center">
                            <b-btn
                                v-b-modal="'modal-remove-post'"
                                variant="white"
                            >
                                {{ $t('common.actions.delete') }}
                            </b-btn>
                        </div>

                        <modal-confirm
                            id="modal-remove-post"
                            body-class="d-none"
                            :title="$t('common.messages.deleteConfirmation', {object: post.getTranslatedAttribute('title')})"
                        >
                            <template #button-confirm>
                                <btn-resource
                                    variant="danger"
                                    class="btn-min-width"
                                    :resource="post"
                                    to-delete
                                    @click="deletePost()"
                                >
                                    {{ $t('common.actions.delete') }}
                                </btn-resource>
                            </template>
                        </modal-confirm>
                    </div>
                </wait-for-resource>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {Post} from '@/models/Post';
import {captureException} from '@sentry/vue';
import Model from '@/models/vue-mc/Model';
import {detainFeedback} from '@/library/helpers';
import PostForm from '@/components/posts/PostForm';
import {UploadCredential} from '@/models/UploadCredential';
import HeaderIndex from '@/components/layouts/HeaderIndex';
import ModalConfirm from '@/components/common/ModalConfirm';
import ConfirmLeaveUnsaved from '@/components/common/mixins/ConfirmLeaveUnsaved';

export default {
    name: 'PostEdit',
    components: {HeaderIndex, ModalConfirm, PostForm},
    mixins: [ConfirmLeaveUnsaved],
    props: {
        id: {
            type: [Number, String],
            required: true,
        },
    },
    data: function() {
        return {
            post: new Post({
                id: this.id,
                video: null,
            }),
        };
    },
    computed: {
        hasUnsavedChanges() {
            return !!this.post.changed();
        },
    },
    async created() {
        const post = this.$store.state.temp.post;

        // Check if there is a saved post in Vuex. If yes, use that as
        // the current post we are editing. Else, fetch post.
        if (post instanceof Post && post.id === this.post.id) {
            this.post = post;

            this.$store.commit('temp/removeItem', 'post');

            // Continue uploading the video to S3.
            await this.uploadVideo();
        } else {
            await this.post.fetch();
        }
    },
    methods: {
        async requestUploadCredential(file, locale) {
            const data = {
                fileName: file.name,
                mimeType: file.type,
                size: file.size,
                duration: file.duration || 0,
                locale,
            };

            const credentials = new UploadCredential({}, null, {
                endpoint: `${Post.endpoint}/${this.id}/request-upload`,
            });

            await credentials.save({data});

            return credentials;
        },
        async deletePost() {
            await this.post.delete();

            detainFeedback(() => {
                // Navigate back to posts index page.
                this.$router.push({name: 'posts.index'});
            });
        },
        async savePost() {
            if (!this.post.changed()) return;

            let shouldSave = true;

            if (this.post.changed().includes('video')) {
                // If upload fails, don't continue to save post.
                shouldSave = await this.uploadVideo();
            }

            if (shouldSave) return this.post.save();
        },
        async uploadVideo() {
            const video = this._.get(this.post, 'video');

            // Manually change the saving state to show spinner in btn-resource.
            this.$set(this.post, 'saving', true);

            const uploads = Object.keys(video)
                .filter(k => video[k].file instanceof File)
                .map(k => {
                    return new Promise(async(resolve, reject) => {
                        let result;

                        try {
                            const credentials = await this.requestUploadCredential(video[k].file, k);

                            result = await this.$refs.videoForm.uploadVideo(credentials, k);
                        } catch (e) {
                            reject(e);
                        }

                        resolve(result);
                    });
                });

            try {
                await Promise.all(uploads);

                this.post.sync('video');
            } catch (e) {
                captureException(e);
            }

            this.$set(this.post, 'saving', false);

            return true;
        },
    },
};
</script>
